<template>
  <div>
    <div class="nav-bar">
      <van-icon name="arrow-left" class="nav-icon" @click="$router.go(-1)" />
      <span>我的消息</span>
    </div>
    <div class="page-layout">
      <ul class="my-ul">
        <li class="list-message" v-for="(item, index) in list" :key="index">
          <!-- 我的消息 -->
          <div class="time" v-if="index === 0">{{ item.created_date }}</div>
          <div
            class="time"
            v-else-if="item.created_time - list[index - 1].created_time > 120"
          >
            {{ item.created_date }}
          </div>
          <!-- 判定为谁的消息，显示不同的样式 -->
          <div class="my-msg" v-if="item.is_my === 0">
            <div class="my-message">
              <div class="my-tag" :class="{ color: item.status * 1 === 1 }">
                <!-- lookOk将会同时控制样式 -->
                {{ item.status * 1 === 1 ? "已读" : "未读" }}
              </div>
              <div class="name-and-content">
                <span>{{ myData.nickname }}</span>
                <div class="message">{{ item.text }}</div>
              </div>
              <div class="head-img-box">
                <img :src="myData.head_img" class="head-img" />
              </div>
            </div>
          </div>
          <!-- 别人的消息 -->
          <div class="you-message" v-else>
            <div class="head-img-box">
              <img :src="groupData.logo" class="head-img" />
            </div>
            <div class="name-content">
              <span>{{ groupData.name }}</span>
              <div class="message">{{ item.text }}</div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="msg-send">
      <input type="text" v-model="msg" />
      <van-icon name="smile-o" size="30" @click="sendMsg" />
      <van-icon name="add-o" size="30" />
    </div>
  </div>
</template>

<script>
import socket from "@/common/js/socket";
import { Toast } from "vant";
export default {
  name: "consult-list",
  data() {
    return {
      goodsId: this.$route.query.goodsId || 0,
      usedId: this.$route.query.usedId || 0,
      shopId: this.$route.query.shopId || 0,
      uId: this.$route.query.uId || 77,
      groupType: this.$route.query.groupType || 1,
      groupId: this.$route.query.groupId || 0,
      list: [],
      msg: "",
      myData: {
        id: 0,
        head_img: "",
        nickname: "",
      },
      groupData: {
        id: 0,
        logo: "",
        m_id: "",
        name: "",
      },
      endId: 0,
    };
  },
  mounted() {
    //事件注册
    let _that = this;
    //接收推送消息
    socket.on("getImInfo", (ret) => {
      if (ret.code * 1 === 1) {
        _that.myData = ret.data.user;
        _that.groupData = ret.data.group;
        _that.groupId = ret.data.group.id;
        _that.uId = ret.data.group.to_id;
        _that.setImList(ret.data.items);
        _that.setRead();
      } else {
        Toast.fail(ret.msg);
      }
    });

    //绑定获取消息通知
    socket.on("sendMsg", (ret) => {
      if (ret.code * 1 === 1) {
        _that.setSendMsg(ret.data);
      } else {
        Toast.fail(ret.msg);
      }
    });
    //获取历史聊天信息
    socket.on("getMsgList", (ret) => {
      console.log(ret);
      if (ret.code * 1 === 1) {
        _that.setImList(ret.data);
      } else {
        Toast.fail(ret.msg);
      }
    });
    //将消息标记为已读
    socket.on("setRead", (ret) => {
      if (ret.code * 1 === 1 && ret.data.look_id * 1 !== _that.myData.id * 1) {
        _that.list.forEach((val, key) => {
          val.status = 1;
        });
      }
    });
    this.getImInfo();
  },

  methods: {
    setSendMsg(data) {
      if (data.m_id * 1 === this.myData.id * 1) {
        data.is_my = 0;
        this.setRead();
      } else {
        data.is_my = 1;
      }
      this.list.push(data);
      //验证是否在底部，在部就刷新到最底页面
    },
    setRead() {
      socket.emit("setRead", {
        group_id: this.groupId,
        look_id: this.myData.id,
      });
    },
    setImList(list) {
      let _that = this;
      list.forEach((val, key) => {
        if (val.m_id * 1 === _that.myData.id * 1) {
          val.is_my = 0;
        } else {
          val.is_my = 1;
        }
        _that.list.unshift(val);
      });
    },
    getImInfo() {
      //获取Im聊天组信
      socket.emit("getImInfo", {
        group_id: this.groupId,
        shop_id: this.shopId,
        handle_id: this.uId,
        group_type: this.groupType,
      });
    },
    getImList() {
      //获取对应组聊天信息
      socket.emit("getImList", {});
    },
    sendMsg() {
      //发送文本消息
      if (this.msg === "") {
        Toast.fail("请输入聊天内容");
        return false;
      }
      this.sendSocketMsg(this.msg, 1);
      this.msg = "";
    },
    sendImg(imgUrl) {
      this.imgUrl = imgUrl;
      if (this.imgUrl === "") {
        Toast.fail("请选择图片");
        return false;
      }
      this.sendSocketMsg(this.imgUrl, 2);
      this.imgUrl = "";
    },
    sendSocketMsg(text, textType) {
      //发送消息
      socket.emit("sendMsg", {
        group_id: this.groupId,
        text_type: textType,
        handle_id: this.uId,
        text: text,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.nav-bar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;
  text-align: center;
  padding: 12px 0;
  font-size: 16px;
  background-color: #fff;
  border-bottom: 1px solid #f2f2f2;
  .nav-icon {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }
}
.page-layout {
  position: relative;
  padding: 58px 12px 74px 12px;
}
.time {
  text-align: center;
}
.message {
  // 消息样式
  max-width: 220px;
  padding: 6px 12px;
  background-color: #f3e1e1;
  border-radius: 10px;
  margin-top: 10px;
}
.head-img-box {
  // 头像样式
  width: 38px;
  img {
    display: block;
    width: 100%;
    border-radius: 50%;
  }
}
// 消息提示样式
.color {
  color: #aaaaaa;
}
// 我的消息
.my-ul {
  margin-top: 12px;
  .list-message {
    padding: 12px 0;
    .my-msg {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .my-message {
        display: flex;
        .my-tag {
          display: flex;
          align-items: end;
          font-size: 12px;
        }
        .name-and-content {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin: 0 10px;
        }
      }
    }
  }
}
// 别人的消息
.you-message {
  display: flex;
  .name-content {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-left: 10px;
  }
}
// 输入框消息发送
.msg-send {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 12px;
  background-color: #fff;
  input {
    width: 290px;
    height: 38px;
    padding: 0 10px;
    background-color: #f8f8f8;
    border: none;
    word-break: break-all;
  }
}
</style>
